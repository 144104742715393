import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
} from "@nextui-org/react";
import React, { useState } from "react";
import { LightningChargeFill } from "react-bootstrap-icons";
import { goCheckout } from "../services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function Checkout(props) {
  const [option, setOption] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const success = searchParams.get("success");
  console.log(success);

  const price = () => {
    switch (option) {
      case 0:
        return {
          id: "price_1R1qWWJWFP3EJ9GUgWvfxNrL",
          value: 50,
        };
      case 1:
        return {
          id: "price_1R1qX8JWFP3EJ9GUlQ8MfI2F",
          value: 100,
        };
      case 2:
        return {
          id: "price_1R1qXZJWFP3EJ9GUNVczgxHG",
          value: 150,
        };
      case 3:
        return {
          id: "price_1R7X4cJWFP3EJ9GU6DdbALlB",
          value: 250,
        };
      default:
        return false;
    }
  };

  const goToStripe = async () => {
    const isDebug = process.env.REACT_APP_DEBUG;
    let response;
    if (isDebug === "1") {
      response = await goCheckout("price_1R7hrrJWFP3EJ9GUfhhEo5g3"); // DEBUG PRODUCT
    } else {
      response = await goCheckout(price().id);
    }
    if (response.data) {
      window.open(response.data, "_self");
    }
  };

  return (
    <div className="flex justify-center mt-auto">
      {success === null && (
        <Card className="p-4 bg-slate-400 dark:bg-slate-800">
          <CardHeader className="flex items-center justify-center mb-4 font-semibold text-lg text-white">
            <p>Purchase tokens</p>
          </CardHeader>
          <Divider />
          <CardBody className="flex gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div
                className={`p-5 rounded-lg shadow flex flex-col justify-center items-center cursor-pointer ${
                  option === 0
                    ? "bg-green-200 dark:bg-green-700 dark:hover:bg-green-600 hover:bg-green-300"
                    : "bg-slate-300 hover:bg-slate-200 dark:hover:bg-slate-500 dark:bg-slate-600 "
                }`}
                onClick={() => setOption(0)}
              >
                <LightningChargeFill className="text-orange-400" />
                <p className="text-xl font-semibold text-slate-600 dark:text-slate-200">
                  100 tokens
                </p>
                <p className="text-slate-500 text-sm dark:text-slate-400">
                  $50
                </p>
              </div>
              <div
                className={`p-5 rounded-lg shadow flex flex-col justify-center items-center cursor-pointer ${
                  option === 1
                    ? "bg-green-200 dark:bg-green-700 dark:hover:bg-green-600 hover:bg-green-300"
                    : "bg-slate-300 hover:bg-slate-200 dark:hover:bg-slate-500 dark:bg-slate-600 "
                }`}
                onClick={() => setOption(1)}
              >
                <LightningChargeFill className="text-orange-400" />
                <p className="text-xl font-semibold text-slate-600 dark:text-slate-200">
                  250 tokens
                </p>
                <p className="text-slate-500 text-sm dark:text-slate-400">
                  $100
                </p>
              </div>
              <div
                className={`p-5 rounded-lg shadow flex flex-col justify-center items-center cursor-pointer ${
                  option === 2
                    ? "bg-green-200 dark:bg-green-700 dark:hover:bg-green-600 hover:bg-green-300"
                    : "bg-slate-300 hover:bg-slate-200 dark:hover:bg-slate-500 dark:bg-slate-600 "
                }`}
                onClick={() => setOption(2)}
              >
                <LightningChargeFill className="text-orange-400" />
                <p className="text-xl font-semibold text-slate-600 dark:text-slate-200">
                  500 tokens
                </p>
                <p className="text-slate-500 text-sm dark:text-slate-400">
                  $150
                </p>
              </div>
              <div
                className={`p-5 rounded-lg shadow flex flex-col justify-center items-center cursor-pointer ${
                  option === 3
                    ? "bg-green-200 dark:bg-green-700 dark:hover:bg-green-600 hover:bg-green-300"
                    : "bg-slate-300 hover:bg-slate-200 dark:hover:bg-slate-500 dark:bg-slate-600 "
                }`}
                onClick={() => setOption(3)}
              >
                <LightningChargeFill className="text-orange-400" />
                <p className="text-xl font-semibold text-slate-600 dark:text-slate-200">
                  1000 tokens
                </p>
                <p className="text-slate-500 text-sm dark:text-slate-400">
                  $250
                </p>
              </div>
            </div>
          </CardBody>
          <Divider />
          <CardFooter className="flex flex-col text-xs">
            <p className="ml-auto text-sm">
              Price:{" "}
              <span className="font-semibold text-base">${price().value}</span>{" "}
              (+23% VAT)
            </p>
            <p className="ml-auto text-sm mb-4">
              Sum:{" "}
              <span className="font-semibold text-base">
                ${price().value + (price().value * 0, 23)}
              </span>
            </p>
            <Button
              color="default"
              className="font-semibold"
              onClick={goToStripe}
            >
              Accept
            </Button>
          </CardFooter>
        </Card>
      )}
      {success !== null && success === "false" && (
        <Card className="p-4 bg-slate-400 dark:bg-slate-800">
          <CardBody className="flex gap-4">
            <div className="mx-auto">
              <p>Payment failed.</p>
            </div>
          </CardBody>
          <CardFooter className="flex flex-col text-xs">
            <Button
              color="default"
              className="font-semibold"
              onClick={() => navigate("/checkout")}
            >
              Try again
            </Button>
          </CardFooter>
        </Card>
      )}
      {success !== null && success === "true" && (
        <Card className="p-4 bg-slate-400 dark:bg-slate-800">
          <CardBody className="flex gap-4">
            <div className="mx-auto">
              <p>Payment succeeded!</p>
            </div>
          </CardBody>
          <CardFooter className="flex flex-col text-xs">
            <Button
              color="default"
              className="font-semibold"
              onClick={() => navigate("/")}
            >
              Ok
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
}
