import React, { useEffect, useState } from "react";
import { Spinner } from "@nextui-org/react";

export default function InfoChip(props) {
  let label = props.value;
  const [loading, setLoading] = useState(false);
  let color = "bg-green-400";

  useEffect(() => {
    setLoading(false);
  });

  if (props.type === "moderation") {
    switch (props.value) {
      case 0:
        label = "Editing (Click to submit)";
        color = "bg-cyan-400";
        break;
      case 1:
        label = "Pending";
        color = "bg-amber-400";
        break;
      case 2:
        label = "Not accepted";
        color = "bg-red-500";
        break;
      case 3:
        label = "Accepted";
        color = "bg-green-400";
        break;
      default:
    }
  }

  if (props.type === "paid") {
    switch (props.value) {
      case true:
        label = "Paid";
        color = "bg-green-400";
        break;
      case false:
        label = "Not paid (Click here to pay)";
        color = "bg-red-400";
        break;
      default:
    }
  }

  if (props.type === "interested") {
    label = `Show (${props.value.length})`;
    color = "bg-slate-400";
  }

  if (props.type === "aiScore") {
    label = `Request AI Score`;
    color = "bg-slate-400";
  }

  return loading ? (
    <div className="flex justify-center">
      <Spinner size="sm" />
    </div>
  ) : (
    <div
      className={`text-black text-center font-semibold text-xs ${color} p-2 py-1 rounded-lg ${
        props.clickable ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (props.clickable) {
          props.onClicked();
          setLoading(true);
        }
      }}
    >
      <p>{label}</p>
    </div>
  );
}
