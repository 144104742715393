import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  ButtonGroup,
  Input,
  Textarea,
  Select,
  SelectItem,
  Switch,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { LightningChargeFill, StarFill } from "react-bootstrap-icons";
import { auth } from "../firebase";
import { getOfferById, imgServer, postOffer, uploadAvatar } from "../services";
import { useNavigate, useParams } from "react-router-dom";

export default function OfferEditor(props) {
  const navigate = useNavigate();
  const isLoggedIn = auth.currentUser !== null;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [tempPic, setTempPic] = useState("");
  const { id } = useParams();
  const [data, setData] = useState({
    type: "full-time",
    imgUrl:
      "https://media.licdn.com/dms/image/v2/D4D16AQGFCGdpyhg4Hw/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1710509394236?e=1735171200&v=beta&t=wb1a31ZXH5AMHPrt1Uc329uXT2P5VrwuAGuyYpM736s",
    title: "",
    company: "",
    description: "",
    category: 1,
    country: "Poland",
    city: "Warszawa",
    remote: true,
    salaryMin: 1000,
    salaryMax: 10000,
    salaryCurrency: "PLN",
    showSalary: true,
  });
  var heroStyle = {
    backgroundImage: `url(${data?.imgUrl ? imgServer + data.imgUrl : null})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(117, 117, 117, 0.5)",
  };

  const submit = async () => {
    await postOffer(data, id ?? null);
    navigate("/offers/manage");
  };

  useEffect(() => {
    if (id) {
      getOffer();
    }
  }, [isLoggedIn]);

  const getOffer = async () => {
    const response = await getOfferById(id, true);
    setData({ ...response, category: response.category ?? "1" });
  };

  const toggleType = (type) => {
    setData({ ...data, type: [type] });
  };

  return (
    <>
      <Modal
        className="dark"
        classNames={{ base: "text-slate-200" }}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Upload logo
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-5 w-full items-center justify-center">
                  {tempPic === "WORKING" ? (
                    <Spinner />
                  ) : (
                    <div
                      className="bg-slate-500 h-48 w-48 rounded-xl"
                      style={{
                        backgroundImage: `url(${imgServer + tempPic ?? null})`,
                        backgroundSize: "cover",
                      }}
                    />
                  )}
                  <p className="text-xs text-slate-500 text-center">
                    Logo should not exceed 1200px in any dimension.
                  </p>
                  <Input
                    type="file"
                    className="max-w-36"
                    onChange={async (e) => {
                      setTempPic("WORKING");
                      const url = await uploadAvatar(e.target.files[0]);
                      if (url) {
                        setTempPic(url);
                      } else {
                        setTempPic("");
                      }
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    setTempPic("");
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="default"
                  onPress={() => {
                    setData({
                      ...data,
                      imgUrl: tempPic,
                    });
                    setTempPic("");
                    onClose();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {props.tokens >= 100 || id ? (
        <div className="w-full h-full grow flex text-slate-200 justify-center">
          <div className="mx-auto fixed top-20 right-16 flex gap-3">
            <Button color="default" onClick={() => navigate("/offers/manage")}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => submit()}>
              {id ? "Save" : "Create"}
            </Button>
          </div>
          <div className="w-full flex flex-col pt-48 max-w-4xl">
            <h1 className="mx-24 text-xl">Offer editor</h1>
            <div className="text-sm mt-8 text-center flex items-between gap-3 mx-24">
              <div
                className="w-48 bg-slate-500 h-48 rounded-xl content-end"
                style={heroStyle}
              >
                {" "}
                <Button
                  className="mx-auto mb-2"
                  variant="bordered"
                  color="default"
                  size="sm"
                  onClick={onOpen}
                >
                  Change picture
                </Button>
              </div>
              <div className="flex flex-col gap-3 grow">
                <Input
                  label="Title"
                  value={data.title}
                  onValueChange={(e) => setData({ ...data, title: e })}
                />
                <Input
                  label="Company"
                  value={data.company}
                  onValueChange={(e) => setData({ ...data, company: e })}
                />
                <Select
                  label="Category"
                  size="sm"
                  selectedKeys={[data.category.toString()]}
                  onSelectionChange={(selection) => {
                    setData({
                      ...data,
                      category: Number(Array.from(selection)[0]),
                    });
                  }}
                >
                  <SelectItem key="0">Any</SelectItem>
                  <SelectItem key="1">Gamedev</SelectItem>
                  <SelectItem key="2">3D Graphics</SelectItem>
                  <SelectItem key="3">2D Graphics</SelectItem>
                  <SelectItem key="4">UI/UX</SelectItem>
                  <SelectItem key="5">AI/ML</SelectItem>
                  <SelectItem key="6">Mobile</SelectItem>
                  <SelectItem key="7">Frontend</SelectItem>
                  <SelectItem key="8">Backend</SelectItem>
                  <SelectItem key="9">Fullstack</SelectItem>
                  <SelectItem key="10">Dev Ops</SelectItem>
                  <SelectItem key="11">Management</SelectItem>
                  <SelectItem key="12">Testing</SelectItem>
                </Select>
                <div className="flex gap-5 items-center justify-end">
                  <p>Job type</p>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      color={
                        data.type?.includes("full-time") ? "warning" : "default"
                      }
                      onClick={() => toggleType("full-time")}
                    >
                      Full time
                    </Button>
                    <Button
                      size="sm"
                      color={
                        data.type?.includes("part-time") ? "warning" : "default"
                      }
                      onClick={() => toggleType("part-time")}
                    >
                      Part time
                    </Button>
                    <Button
                      size="sm"
                      color={
                        data.type?.includes("outsourcing")
                          ? "warning"
                          : "default"
                      }
                      onClick={() => toggleType("outsourcing")}
                    >
                      Outsourcing
                    </Button>
                    <Button
                      size="sm"
                      color={
                        data.type?.includes("freelance") ? "warning" : "default"
                      }
                      onClick={() => toggleType("freelance")}
                    >
                      Freelance
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div className="mx-24">
              <Textarea
                label="Description"
                value={data.description}
                className="mt-6"
                minRows={10}
                maxLength={5000}
                onValueChange={(e) => setData({ ...data, description: e })}
              />
              <p className="text-xs text-gray-400 text-right">
                {data?.description.length}/5000
              </p>
            </div>
            <div className="mx-24 mt-8 flex flex-col gap-3 justify-center text-sm">
              <Select
                isRequired
                label="Country"
                selectedKeys={[data.country]}
                onSelectionChange={(selection) => {
                  setData({ ...data, country: Array.from(selection)[0] });
                }}
              >
                <SelectItem startContent={<StarFill />} key="Remote">
                  Remote
                </SelectItem>
                <SelectItem key="Argentina">Argentina</SelectItem>
                <SelectItem key="Australia">Australia</SelectItem>
                <SelectItem key="Brazil">Brazil</SelectItem>
                <SelectItem key="Canada">Canada</SelectItem>
                <SelectItem key="Chile">Chile</SelectItem>
                <SelectItem key="China">China</SelectItem>
                <SelectItem key="Egypt">Egypt</SelectItem>
                <SelectItem key="Finland">Finland</SelectItem>
                <SelectItem key="France">France</SelectItem>
                <SelectItem key="Germany">Germany</SelectItem>
                <SelectItem key="India">India</SelectItem>
                <SelectItem key="Italy">Italy</SelectItem>
                <SelectItem key="Japan">Japan</SelectItem>
                <SelectItem key="Malaysia">Malaysia</SelectItem>
                <SelectItem key="Mexico">Mexico</SelectItem>
                <SelectItem key="Netherlands">Netherlands</SelectItem>
                <SelectItem key="NewZealand">New Zealand</SelectItem>
                <SelectItem key="Poland">Poland</SelectItem>
                <SelectItem key="Russia">Russia</SelectItem>
                <SelectItem key="Singapore">Singapore</SelectItem>
                <SelectItem key="SouthAfrica">South Africa</SelectItem>
                <SelectItem key="SouthKorea">South Korea</SelectItem>
                <SelectItem key="Spain">Spain</SelectItem>
                <SelectItem key="Sweden">Sweden</SelectItem>
                <SelectItem key="Ukraine">Ukraine</SelectItem>
                <SelectItem key="UnitedArabEmirates">
                  United Arab Emirates
                </SelectItem>
                <SelectItem key="UnitedKingdom">United Kingdom</SelectItem>
                <SelectItem key="UnitedStates">United States</SelectItem>
                <SelectItem key="Vietnam">Vietnam</SelectItem>
              </Select>
              <Input
                label="City"
                value={data.city}
                onValueChange={(e) => setData({ ...data, city: e })}
              />
              <div className="flex items-center justify-between">
                <p>Remote work possibility</p>
                <Switch
                  isSelected={data.remote}
                  onValueChange={() =>
                    setData({ ...data, remote: !data.remote })
                  }
                />
              </div>
              <div className="flex items-center justify-between">
                <p>Salary range</p>
                <div className="flex gap-3">
                  <Input
                    isRequired
                    label="Salary Min"
                    type="number"
                    value={data.salaryMin}
                    onValueChange={(e) => setData({ ...data, salaryMin: e })}
                  />
                  <Input
                    isRequired
                    label="Salary Max"
                    type="number"
                    value={data.salaryMax}
                    onValueChange={(e) => setData({ ...data, salaryMax: e })}
                  />
                  <Select
                    isRequired
                    label="Currency"
                    selectedKeys={[data.salaryCurrency]}
                    onSelectionChange={(selection) => {
                      setData({
                        ...data,
                        salaryCurrency: Array.from(selection)[0],
                      });
                    }}
                  >
                    <SelectItem key="PLN">PLN</SelectItem>
                    <SelectItem key="USD">USD</SelectItem>
                    <SelectItem key="EUR">EUR</SelectItem>
                  </Select>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p>Show salary</p>
                <Switch
                  isSelected={data.showSalary}
                  onValueChange={() =>
                    setData({ ...data, showSalary: !data.showSalary })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full flex flex-col gap-4 mx-auto my-auto text-slate-400 items-center justify-center">
          <p>You don't have enough tokens to publish an offer :(</p>
          <Button
            startContent={<LightningChargeFill />}
            color="warning"
            onClick={() => navigate("/checkout")}
          >
            Get tokens
          </Button>
        </div>
      )}
    </>
  );
}
