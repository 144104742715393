import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Avatar,
  Badge,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Switch,
} from "@nextui-org/react";
import {
  BellFill,
  BoxArrowLeft,
  BriefcaseFill,
  ChatFill,
  EyeFill,
  LightningChargeFill,
  MoonFill,
  PencilFill,
  PlusLg,
  SunFill,
} from "react-bootstrap-icons";
import { auth } from "./../firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo_orange.png";
import { fetchUnread, imgServer } from "../services";

export default function Navigation(props) {
  const [unread, setUnread] = useState(0);
  const navigate = useNavigate();
  const isLoggedIn = auth.currentUser !== null;

  useEffect(() => {
    getUnread();
  }, [isLoggedIn]);

  const getUnread = async () => {
    if (isLoggedIn) {
      const res = await fetchUnread();
      if (res) {
        setUnread(res);
      }
    }
  };

  const switchTheme = () => {
    const newTheme = props.theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    props.setTheme(newTheme);
  };

  return (
    <Navbar
      className="flex w-full fixed"
      classNames={{ base: "bg-black/30" }}
      shouldHideOnScroll
    >
      <NavbarBrand onClick={() => navigate("/")} className="cursor-pointer">
        <div
          className="w-6 h-6 sm:w-10 sm:h-10 sm:mr-2"
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
        <p className="hidden sm:block font-semibold text-white">HackerHive</p>
      </NavbarBrand>
      <NavbarContent justify="center">
        <Button
          variant="solid"
          color="primary"
          size="sm"
          startContent={<BriefcaseFill className="text-white" />}
          className="hidden font-semibold sm:flex"
          onClick={() => navigate("/offers")}
        >
          Browse Jobs
        </Button>
      </NavbarContent>
      {isLoggedIn ? (
        <NavbarContent
          className="ml-auto text-orange-400 flex gap-2 text-lg"
          justify="end"
        >
          <NavbarItem className="my-auto">
            <Switch
              size="sm"
              startContent={<SunFill />}
              endContent={<MoonFill />}
              isSelected={props.theme === "light"}
              onValueChange={switchTheme}
            />
          </NavbarItem>
          {props?.profile?.accountType === "COMPANY" && (
            <NavbarItem>
              <div
                className="bg-slate-200 bg-opacity-30 rounded-lg shadow flex items-center p-1 hover:bg-slate-300 cursor-pointer"
                onClick={() => navigate("/checkout")}
              >
                <LightningChargeFill />
                <p className="text-sm text-white px-1 font-semibold">
                  {props?.profile?.tokens}
                </p>
              </div>
            </NavbarItem>
          )}
          <NavbarItem className="cursor-pointer">
            <Dropdown
              classNames={{
                base: "mt-8",
                content: "text-slate-800",
              }}
            >
              <DropdownTrigger>
                <Badge
                  isInvisible={
                    props.profile && props.profile.notifications?.length === 0
                  }
                  size="sm"
                  content="3"
                  color="danger"
                  showOutline={false}
                >
                  <DropdownTrigger>
                    <Button variant="light" isIconOnly>
                      <BellFill className="text-slate-200" />
                    </Button>
                  </DropdownTrigger>
                </Badge>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                emptyContent={
                  <p className="text-center">You have no notifications</p>
                }
              ></DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Badge
              isInvisible={unread === 0}
              size="sm"
              content={unread}
              color="danger"
              showOutline={false}
            >
              <Button
                variant="light"
                isIconOnly
                onClick={() => navigate(`messages`)}
              >
                <ChatFill className="text-slate-200" />
              </Button>
            </Badge>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Dropdown classNames={{ content: "text-slate-700" }}>
              <DropdownTrigger>
                <Button variant="light" isIconOnly radius="full">
                  <Avatar
                    showFallback
                    isBordered
                    src={`${imgServer}${props.profile?.avatarUrl}`}
                    size="sm"
                  />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {props.profile?.accountType === "COMPANY" && (
                  <DropdownSection
                    showDivider
                    classNames={{ divider: "bg-slate-500" }}
                  >
                    <DropdownItem
                      startContent={<PlusLg />}
                      key="newoffer"
                      onClick={() => navigate(`/offers/create`)}
                    >
                      Create offer
                    </DropdownItem>
                  </DropdownSection>
                )}
                <DropdownSection>
                  <DropdownItem
                    startContent={<EyeFill />}
                    key="myprofile"
                    onClick={() => navigate(`/${auth.currentUser.uid}`)}
                  >
                    My profile
                  </DropdownItem>
                  <DropdownItem
                    startContent={<PencilFill />}
                    key="editprofile"
                    onClick={async () => {
                      await props.refresh();
                      navigate(`/profile/edit`);
                    }}
                  >
                    Edit profile
                  </DropdownItem>
                  {props.profile?.accountType === "COMPANY" ? (
                    <DropdownItem
                      startContent={<BriefcaseFill />}
                      key="jobs"
                      onClick={() => navigate(`/offers/manage`)}
                    >
                      Manage offers
                    </DropdownItem>
                  ) : null}
                  <DropdownItem
                    startContent={<BoxArrowLeft />}
                    key="logout"
                    className="text-danger"
                    color="danger"
                    onClick={async () => {
                      await auth.signOut();
                      navigate("/");
                    }}
                  >
                    Log out
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        </NavbarContent>
      ) : (
        <NavbarContent
          className="ml-auto text-green-400 flex gap-5 text-lg"
          justify="end"
        >
          <NavbarItem className="my-auto">
            <Switch
              size="sm"
              startContent={<SunFill />}
              endContent={<MoonFill />}
              isSelected={props.theme === "light"}
              onValueChange={switchTheme}
            />
          </NavbarItem>
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="default"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </Button>
          </NavbarItem>
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="success"
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
          </NavbarItem>
        </NavbarContent>
      )}
    </Navbar>
  );
}
