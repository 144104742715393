import React from "react";
import moment from "moment";
import { Briefcase, GeoAltFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Chip } from "@nextui-org/react";
import { imgServer } from "../services";

export default function OfferStrip(props) {
  const navigate = useNavigate();
  const { offer } = props;

  const subtypeChips = offer?.subtype?.map((subtype) => {
    return (
      <Chip size="sm" radius="sm" variant="flat">
        {subtype}
      </Chip>
    );
  });

  return (
    <div
      className="w-full bg-white dark:bg-slate-800 p-2 mx-auto rounded-lg text-white flex items-center gap-2 cursor-pointer hover:bg-gray-100 shadow-md"
      onClick={() => navigate(`/offer/${offer.id}`)}
    >
      {offer.imgUrl !== "" ? (
        <div
          className="rounded-xl w-16 h-16"
          style={{
            backgroundImage: `url(${imgServer + offer.imgUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      ) : (
        <Briefcase className="text-orange-500 text-2xl mx-4 hidden sm:block" />
      )}
      <div className="mr-2 grow">
        <p className="text-sm sm:text-base text-slate-700 dark:text-slate-200">
          {offer.title}
        </p>
        <p className="text-gray-700 text-xs dark:text-slate-300">{`at ${offer.company}`}</p>
        <div className="mt-2 flex justify-between">
          <div className="flex items-center">
            <GeoAltFill className="text-orange-500 text-xs mr-1" />
            <p className="text-gray-700 text-xs dark:text-slate-300">
              {`${offer.city ?? ""} ${offer.country ?? ""} ${
                offer.location !== "Remote" && offer.remote ? "(remote)" : ""
              }`}
            </p>
          </div>
        </div>
      </div>
      <div className="sm:mx-4 ml-auto flex flex-col items-end">
        <div className="flex gap-1">{subtypeChips}</div>
        <p className="text-sm text-orange-400 font-semibold">
          {offer.showSalary
            ? `${offer.salaryMin} - ${offer.salaryMax} ${offer.salaryCurrency}`
            : "Undisclosed salary"}
        </p>
        <p className="text-xs text-gray-700 text-right dark:text-slate-300">
          {moment(offer.added).fromNow()}
        </p>
      </div>
    </div>
  );
}
