import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";
import React, { useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { trySetAccountType } from "../services";

export default function AccountTypeModal(props) {
  const [option, setOption] = useState("HACKER");
  const showModal = props.showModal;

  const handleSubmit = async () => {
    const result = await trySetAccountType(option);
    if (result === true) {
      window.location.reload();
    }
  };

  return (
    <>
      <Modal
        className="dark"
        classNames={{ base: "text-slate-200" }}
        isOpen={showModal}
        backdrop="blur"
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        hideCloseButton
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            <div className="flex items-center gap-3">
              <InfoCircle className="text-amber-400" />
              <p>Choose Account Type</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <p className="text-slate-200 text-sm">
                Please pick your account type. You can only do this once, so
                please choose wisely.
              </p>
              <ButtonGroup fullWidth className="mt-6">
                <Button
                  size="sm"
                  color={option === "HACKER" ? "warning" : "default"}
                  onClick={() => setOption("HACKER")}
                >
                  HACKER
                </Button>
                <Button
                  size="sm"
                  color={option === "COMPANY" ? "warning" : "default"}
                  onClick={() => setOption("COMPANY")}
                >
                  COMPANY
                </Button>
              </ButtonGroup>
              <p className="text-xs text-center mt-2 text-slate-400">
                {option === "HACKER"
                  ? "I'm a hacker looking for new opportunities."
                  : "I'm a recruiter, and i want to post job offers on HackerHive."}
              </p>
            </div>
            <Button color="success" className="my-4" onClick={handleSubmit}>
              Accept
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
