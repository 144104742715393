import React from "react";
import { Button } from "@nextui-org/react";
import News from "./News";
import {
  Binoculars,
  BoxFill,
  Bullseye,
  CaretDownFill,
  Chat,
  CheckAll,
  Cpu,
  FilePost,
  GearFill,
  LightningChargeFill,
  PersonRaisedHand,
  RocketTakeoffFill,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

export default function Home(props) {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div
          className={`py-48 pb-40 flex flex-col justify-center shadow-xl ${
            props.theme === "dark" ? "herogradientdark" : "herogradient"
          }`}
        >
          <div className="mx-auto mb-6">
            <div className="text-white flex items-baseline gap-6">
              <LightningChargeFill className="text-4xl text-orange-500" />
              <h1 className="text-2xl sm:text-4xl md:text-6xl text-white text-center">
                AI-Powered Job Matching
              </h1>
              <LightningChargeFill className="text-4xl text-orange-500" />
            </div>
            <div className="absolute inset-x-0 flex flex-col justify-center pt-2">
              <p className="mt-4 text-sm text-slate-200 mx-auto text-center max-w-3xl font-semibold italic shadow-lg">
                HackerHive helps developers find jobs faster and makes hiring
                easier with AI-driven candidate scoring.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 mt-16 sm:mt-0 flex flex-col justify-center">
        <div className="mt-16 flex items-center mx-auto gap-4">
          <GearFill className="text-orange-400 text-3xl" />
          <h3 className="text-slate-600 dark:text-slate-300 text-3xl font-semibold text-center">
            How It Works?
          </h3>
        </div>
        <div className="mt-20 grid grid-cols-3 text-white">
          <div className="flex flex-col items-center gap-12 ml-auto">
            <h3 className="text-2xl font-semibold text-slate-500 dark:text-slate-300 mb-6">
              Recruiter
            </h3>
            <div className="flex flex-col items-center">
              <div className="flex text-orange-400 text-2xl sm:text-5xl h-fit">
                <FilePost />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Post a Job Offer
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Post an offer, just like on any other job board, and wait for
                candidates to show their interest.
              </p>
            </div>
            <CaretDownFill className="text-slate-500" />
            <div className="flex flex-col items-center">
              <div className="flex text-orange-400 text-2xl sm:text-5xl h-fit">
                <Cpu />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Get AI-Score for candidates
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Interested candidates are then scored by their fit to the job
                description.
              </p>
            </div>
            <CaretDownFill className="text-slate-500" />
            <div className="flex flex-col items-center">
              <div className="flex text-orange-400 text-2xl sm:text-5xl h-fit">
                <CheckAll />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Hire Smarter & Faster
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Get top talent effortlessly.
              </p>
            </div>
          </div>
          <div className="bg-slate-300 dark:bg-slate-700 w-[1px] mx-auto" />
          <div className="flex flex-col items-center gap-12 mr-auto">
            <h3 className="text-2xl font-semibold text-slate-500 dark:text-slate-300 mb-6">
              Candidate
            </h3>
            <div className="flex flex-col items-center">
              <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
                <FilePost />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Create a Free Profile
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Describe your skills, past projects & experience.
              </p>
            </div>
            <CaretDownFill className="text-slate-500" />
            <div className="flex flex-col items-center">
              <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
                <Cpu />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Get Job Offers
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Receive job offers that fit your profile.
              </p>
            </div>
            <CaretDownFill className="text-slate-500" />
            <div className="flex flex-col items-center">
              <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
                <CheckAll />
              </div>
              <h5 className="text-slate-700 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
                Apply & Get Hired
              </h5>
              <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
                Get noticed by top recruiters.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-32 flex items-center mx-auto gap-4">
          <BoxFill className="text-orange-400 text-3xl" />
          <h3 className="text-slate-500 dark:text-slate-300 text-3xl font-semibold text-center">
            What Do You Get?
          </h3>
        </div>
        <div className="flex mt-16">
          <div className="flex flex-col sm:flex-row items-center mx-auto mt-12">
            <div className="text-slate-400 text-7xl md:text-9xl h-fit">
              <PersonRaisedHand />
            </div>
            <div className="text-black p-8 pt-2 sm:p-8 text-center sm:text-left">
              <h3 className="text-xl sm:text-2xl font-semibold text-slate-600 dark:text-slate-300 text-center sm:text-left">
                For Candidates
              </h3>
              <h5 className="italic mb-4 text-slate-500">
                Create a free profile and let the job find you!
              </h5>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> Get matched instantly to the best jobs.
              </p>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> No endless job searching – Let AI do the
                work.
              </p>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> Show off your skills & projects to top
                companies.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center mx-auto mt-12 text-center sm:text-left">
            <div className="text-slate-400 text-9xl h-fit">
              <Binoculars />
            </div>
            <div className="text-black p-8">
              <h3 className="text-2xl font-semibold text-slate-600 dark:text-slate-300">
                For Recruiters
              </h3>
              <h5 className="italic mb-4 text-slate-500">
                Find Your next superstar candidate!
              </h5>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> Instantly see top-matched candidates for
                your job.
              </p>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> Save time filtering through applications.
              </p>
              <p className="text-xs sm:text-sm font-semibold text-slate-500">
                <span>&#8226;</span> Hire better, faster, and smarter.
              </p>
            </div>
          </div>
        </div>
        <h3 className="mt-32 text-slate-500 dark:text-slate-300 text-2xl font-semibold text-center">
          Main Features
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-5 mt-16 sm:mt-24">
          <div className="flex flex-col items-center">
            <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
              <Bullseye />
            </div>
            <h5 className="text-slate-600 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
              Efficient & Focused
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              No more spamming with resumes - let us do the heavy lifting.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
              <Cpu />
            </div>
            <h5 className="text-slate-600 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
              ML Candidate Scoring
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              Our custom AI models assess if candidate's skills and experience
              are a good fit for a given offer.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-orange-400 text-2xl sm:text-5xl h-fit">
              <Chat />
            </div>
            <h5 className="text-slate-600 dark:text-slate-300 text-center mt-4 text-sm sm:text-base">
              Direct Messaging
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              Connect directly through HackerHive.
            </p>
          </div>
        </div>
        <p className="text-3xl text-slate-500 dark:text-slate-300 text-center mt-24 mb-12">
          Ready to join the Hive?
        </p>
        <Button
          className="mx-auto font-semibold"
          color="warning"
          variant="solid"
          startContent={<RocketTakeoffFill />}
          onClick={() => navigate("register")}
        >
          Sign Up For Free
        </Button>
      </div>
    </div>
  );
}
