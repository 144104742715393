import axios from "axios";
import { auth, analytics } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { logEvent } from "firebase/analytics";
const api = process.env.REACT_APP_BACKEND;
export const imgServer =
  "https://storage.googleapis.com/hackerhive-f5c20.appspot.com/";

export async function goCheckout(productId) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.post(
        `${api}/checkout`,
        { product: productId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function tryLogin(email, password) {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "login");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function tryRegister(email, password) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "sign_up");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchMyProfile() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.get(`${api}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateMyProfile(profile) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.put(`${api}/user/profile`, profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.status;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchProfile(id, isShortcut) {
  try {
    const paramName = isShortcut ? "short" : "uid";
    const result = await axios.get(`${api}/user?${paramName}=${id}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function uploadAvatar(file) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const form = new FormData();
      form.append("image", file);
      const result = await axios.post(`${api}/upload`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function trySetUsername(username, currentUsername) {
  let result = {
    success: false,
    message: "",
  };
  if (username === currentUsername) {
    result.message = "Username not changed.";
    return result;
  }
  if (username.length === 0) {
    result.message = "Username empty";
    return result;
  }

  try {
    const token = await auth.currentUser.getIdToken();
    const body = {
      shortcut: username,
    };
    const response = await axios.put(`${api}/user/shortcut`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      result.success = true;
      return result;
    } else {
      result.message = "Couldn't change username";
      return result;
    }
  } catch (e) {
    console.log(e);
    return result;
  }
}

// USER: Set Account Type
export async function trySetAccountType(accountType) {
  try {
    const token = await auth.currentUser.getIdToken();
    const body = {
      type: accountType,
    };
    const response = await axios.put(`${api}/user/accounttype`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function fetchConversations() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${api}/conversations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function fetchConversation(id) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${api}/conversation?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function fetchUnread() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${api}/conversations/unread`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data.num;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function sendMessage(message, recipient) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const data = {
        recipient: recipient,
        message: message,
      };
      const response = await axios.post(`${api}/message`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function getMyId() {
  if (auth.currentUser) {
    return auth.currentUser.uid;
  } else {
    return false;
  }
}

export async function postOffer(offer, id) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const endpoint = id ? `update?id=${id}` : "create";
      const response = await axios.post(`${api}/offer/${endpoint}`, offer, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteOffer(id) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.delete(`${api}/offer?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function submitForModeration(id) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        `${api}/offer/submit?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getOwnedOffers() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${api}/offer/owned`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getInterestedUsers(id) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${api}/offer/interestedprofiles?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getOffers(
  country,
  category,
  remote,
  start,
  limit,
  nodetails
) {
  try {
    const response = await axios.post(`${api}/offers`, {
      country: country,
      category: category,
      remote: remote,
      start: start,
      limit: limit,
      nodetails: nodetails,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getOffersNum() {
  try {
    const response = await axios.get(`${api}/countoffers`);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getOfferById(id, full) {
  let token;
  try {
    if (auth.currentUser) {
      token = await auth.currentUser.getIdToken();
    }
    const response = await axios.get(
      `${api}/${full ? "readoffer" : "offer"}?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function imInterested(id) {
  let token;
  try {
    if (auth.currentUser) {
      token = await auth.currentUser.getIdToken();
    }
    const response = await axios.get(`${api}/offer/interested?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getUsers(location, start, limit) {
  try {
    const response = await axios.post(`${api}/radar/users`, {
      location: location,
      start: start,
      limit: limit,
      nodetails: true,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
