import { Button } from "@nextui-org/react";
import React from "react";
import {
  Activity,
  Braces,
  BrushFill,
  CardText,
  Cpu,
  XLg,
} from "react-bootstrap-icons";

export default function SkillChip(props) {
  return (
    <div className="flex items-center gap-4 bg-white dark:bg-slate-800 rounded-xl p-2 pl-4 text-green-400">
      {props.icon === 1 && <Braces />}
      {props.icon === 2 && <BrushFill />}
      {props.icon === 3 && <Activity />}
      {props.icon === 4 && <CardText />}
      {props.icon === 5 && <Cpu />}
      <div>
        <p className="text-sm text-slate-800 dark:text-white">{props.title}</p>
        {props.seniority === 1 && (
          <p className="text-xs text-slate-400">Beginner</p>
        )}
        {props.seniority === 2 && (
          <p className="text-xs text-slate-400">Intermediate</p>
        )}
        {props.seniority === 3 && (
          <p className="text-xs text-slate-400">
            I'm comfortable using this skill
          </p>
        )}
        {props.seniority === 4 && (
          <p className="text-xs text-slate-400">Professional level</p>
        )}
      </div>
      {props.editable && (
        <Button
          isIconOnly
          size="sm"
          variant="ghost"
          className="ml-auto"
          onClick={() => props.delete(props.index)}
        >
          <XLg />
        </Button>
      )}
    </div>
  );
}
