import { Button } from "@nextui-org/react";
import { XLg } from "react-bootstrap-icons";
import React from "react";

export default function WorkHistoryChip(props) {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-xl flex my-6">
      <div className="p-4">
        <div className="flex gap-1 items-center">
          <p className="text-green-400 font-semibold">{props.company}</p>
          <p className="text-slate-400 text-sm">{`(${props.position})`}</p>
        </div>
        <p className="text-sm text-slate-400">{props.description}</p>
      </div>
      {props.editable && (
        <Button
          isIconOnly
          size="sm"
          variant="ghost"
          className="ml-auto my-auto relative -right-14"
          onClick={() => props.delete(props.index)}
        >
          <XLg />
        </Button>
      )}
    </div>
  );
}
