import React from "react";

export default function InfoChipProfile(props) {
  return (
    <div className="bg-white dark:bg-gray-800 text-xs text-white font-base rounded-lg flex gap-2 items-center">
      {props.icon ? (
        <div className="ml-2 text-gray-700 dark:text-white">{props.icon}</div>
      ) : (
        <p className="ml-2 text-gray-700 dark:text-white">{props.title}</p>
      )}
      <div className="bg-slate-300 dark:bg-green-400 text-black p-1 rounded-r-lg">
        <p>{props.value}</p>
      </div>
    </div>
  );
}
