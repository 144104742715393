import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferById, imgServer, imInterested } from "../services";
import { Button, Divider, Spinner } from "@nextui-org/react";
import {
  BarChartFill,
  Check,
  CpuFill,
  HandThumbsUpFill,
} from "react-bootstrap-icons";

export default function OfferView(props) {
  const isLoggedIn = auth.currentUser !== null;
  const navigate = useNavigate();
  const [offer, setOffer] = useState(null);
  const [render, setRender] = useState(0);
  const { id } = useParams();
  const notOwner = !auth.currentUser || offer?.owner !== auth.currentUser.uid;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOffer();
  }, [isLoggedIn, render]);

  const getOffer = async () => {
    const response = await getOfferById(id, false);
    setOffer(response);
  };

  const onInterested = async () => {
    setLoading(true);
    if (!auth.currentUser) {
      navigate("/");
    } else {
      await imInterested(id);
      await props.refresh();
      setRender(1);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full xl:w-3/5 mt-16 sm:mt-24 h-full grow md:p-10 mx-auto">
      <div className="flex flex-col rounded-2xl p-2 bg-black bg-opacity-30 h-full grow">
        {!offer && (
          <div className="text-gray-700 dark:text-gray-500 mx-auto my-auto">
            <p>Offer not found.</p>
          </div>
        )}
        {offer && (
          <div>
            <div className="flex items-center w-full">
              <div
                className="rounded-xl w-40 h-36 m-4"
                style={{
                  backgroundImage: `url(${imgServer + offer.imgUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <div className="mx-2 flex items-center w-full justify-between">
                <div>
                  <div className="flex gap-3 items-center mb-2">
                    <p className="font-semibold text-xl sm:text-2xl text-slate-800 dark:text-slate-200">
                      {offer.title}
                    </p>
                    <div
                      className={`p-1 rounded text-xs font-semibold bg-amber-500`}
                    >
                      <p>{offer.type}</p>
                    </div>
                  </div>
                  <p className="text-slate-800 dark:text-slate-400 text-sm">{`for: ${offer.company}`}</p>
                  <p className="text-slate-800 dark:text-slate-400 text-sm">{`at: ${
                    offer.city ?? ""
                  } ${offer.country ?? ""} ${
                    offer.location !== "Remote" && offer.remote
                      ? "(remote)"
                      : ""
                  }`}</p>
                  <p className="text-slate-800 dark:text-slate-400 text-sm">
                    {offer.showSalary
                      ? `${offer.salaryMin} - ${offer.salaryMax} ${offer.salaryCurrency}`
                      : "Undisclosed salary"}
                  </p>
                </div>
                <div className="flex gap-2 mr-8">
                  {notOwner && (
                    <div>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <div className="flex flex-col items-center gap-3">
                          {!props.appliedOffers ||
                          !props.appliedOffers.includes(id) ? (
                            <div>
                              <Button
                                variant="solid"
                                color="success"
                                className="hidden sm:flex"
                                startContent={<HandThumbsUpFill />}
                                onClick={onInterested}
                              >
                                I'm interested
                              </Button>
                              <Button
                                variant="flat"
                                color="warning"
                                className="sm:hidden"
                                isIconOnly
                                startContent={<HandThumbsUpFill />}
                                onClick={onInterested}
                              />
                            </div>
                          ) : (
                            <p className="text-slate-800 dark:text-slate-300 flex items-center gap-1">
                              <span>
                                <Check />
                              </span>
                              Interested
                            </p>
                          )}
                          {props.accountType &&
                            props.accountType === "HACKER" && (
                              <Button
                                variant="solid"
                                color="success"
                                className="hidden sm:flex"
                                startContent={<BarChartFill />}
                                onClick={onInterested}
                              >
                                AI Score
                              </Button>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider className="mt-3" />
            <div className="flex justify-end gap-3 mx-6">
              <pre className="font-sans">
                <p className="w-full text-slate-800 dark:text-slate-200 my-6 text-balance">
                  {offer.description}
                </p>
              </pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
