import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Spinner } from "@nextui-org/react";
import {
  Box2Fill,
  BriefcaseFill,
  CardText,
  ClockHistory,
  EnvelopeFill,
  GlobeAmericas,
  Link,
  Link45deg,
  StarFill,
  TelephoneFill,
  Tools,
  Translate,
} from "react-bootstrap-icons";
import InfoChipProfile from "./InfoChipProfile";
import SkillChip from "./SkillChip";
import ProjectChip from "./ProjectChip";
import WorkHistoryChip from "./WorkHistoryChip";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProfile, imgServer } from "../services";
import { auth } from "./../firebase";

export default function Profile(props) {
  const isLoggedIn = auth.currentUser !== null;
  const navigate = useNavigate();
  let { id } = useParams();
  const isShortcut = id.length <= 20;
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    async function getData() {
      const data = await fetchProfile(id, isShortcut);
      if (data) {
        setProfile(data);
      }
    }
    getData();
  }, []);

  const skillMap = profile.skills?.map((skill, index) => {
    return (
      <SkillChip
        title={skill.name}
        seniority={skill.seniority}
        icon={skill.icon}
        index={index}
        key={index}
      />
    );
  });

  const workHistoryMap = profile.workHistory?.map((entry, index) => {
    return (
      <WorkHistoryChip
        company={entry.company}
        position={entry.position}
        description={entry.description}
        index={index}
        key={index}
      />
    );
  });

  const projectMap = profile.projects?.map((project, index) => {
    return (
      <ProjectChip
        name={project.name}
        pic={project.pic}
        role={project.role}
        link={project.link}
        index={index}
        key={index}
      />
    );
  });

  const linkMap = profile.links?.map((link, index) => {
    return (
      <Button
        key={index}
        className="bg-slate-300 dark:bg-green-500 dark:text-black font-semibold"
        size="sm"
        startContent={<Link />}
      >
        <a href={link.value}>{link.label}</a>
      </Button>
    );
  });

  return (
    <div className="w-full h-full flex text-slate-800 justify-center">
      {!profile && <Spinner size="lg" />}
      {profile && (
        <div className="flex flex-col pt-48 max-w-2xl">
          <div className="mx-auto">
            <Avatar
              showFallback
              isBordered
              src={`${imgServer}${profile.avatarUrl}`}
              className="w-40 h-40"
            />
          </div>
          <div className="mx-auto text-center flex flex-col items-center">
            <h1 className="text-xl mt-4 dark:text-slate-200">{profile.name}</h1>
            <p className="text-slate-600 dark:text-slate-400">
              {profile.position}
            </p>
            {profile.accountType === "COMPANY" && (
              <div className="p-1 px-2 mt-2 rounded-lg mx-auto bg-teal-300/60">
                <p className="text-black font-semibold text-xs">
                  COMPANY PROFILE
                </p>
              </div>
            )}
            {profile.openToWork === true &&
              profile.accountType === "HACKER" && (
                <div className="p-1 px-2 mt-2 rounded-lg mx-auto bg-yellow-400">
                  <p className="text-black font-semibold text-xs">
                    OPEN TO WORK
                  </p>
                </div>
              )}
            {isLoggedIn && auth.currentUser?.uid !== profile.id ? (
              <div className="mt-4 flex gap-3">
                <Button
                  variant="flat"
                  size="sm"
                  startContent={<EnvelopeFill />}
                  color="success"
                  onClick={() => navigate(`/messages/${profile.id}`)}
                >
                  Message
                </Button>
                <Button isIconOnly size="sm" variant="flat" color="success">
                  <StarFill />
                </Button>
              </div>
            ) : (
              <div className="mt-4 text-gray-400 text-xs">
                <p>
                  {auth.currentUser?.uid === profile.id
                    ? ""
                    : "Log in to contact this user"}
                </p>
              </div>
            )}
          </div>
          <Divider className="mt-8 bg-slate-300 dark:bg-slate-600" />
          <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-300">
            <CardText />
            <h5>Overview</h5>
          </div>
          <p className="text-slate-600 mt-8 text-pretty dark:text-slate-400">
            {profile.description}
          </p>
          <div className="mt-8 flex gap-4 justify-center">
            {profile.accountType === "HACKER" && (
              <InfoChipProfile
                title="Exp"
                value={`${profile.experienceYears} yrs`}
              />
            )}
            {profile.location && (
              <InfoChipProfile
                icon={<GlobeAmericas />}
                value={profile.location}
              />
            )}
            {profile?.languages?.length > 0 && (
              <InfoChipProfile
                icon={<Translate />}
                value={profile?.languages}
              />
            )}
            {profile?.contactInfo.phone !== "" && (
              <InfoChipProfile
                icon={<TelephoneFill />}
                value={profile?.contactInfo.phone}
              />
            )}
            {profile?.contactInfo.email !== "" && (
              <InfoChipProfile title="@" value={profile?.contactInfo.email} />
            )}
          </div>
          {profile.accountType === "HACKER" && (
            <div>
              <Divider className="mt-8 bg-slate-300 dark:bg-slate-600 dark:text-slate-300" />
              <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-400">
                <Tools />
                <h5>Skills</h5>
              </div>
              {skillMap.length > 0 && (
                <div className="grid grid-cols-2 gap-4 mt-8">{skillMap}</div>
              )}
              {skillMap.length === 0 && (
                <p className="mt-8 text-center text-slate-600 dark:text-slate-400 italic text-sm">
                  No skills yet
                </p>
              )}
            </div>
          )}
          {profile.accountType === "HACKER" && (
            <div>
              <Divider className="mt-8 bg-slate-300 dark:bg-slate-600 dark:text-slate-300" />
              <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-400">
                <ClockHistory />
                <h5>Work History</h5>
              </div>
              {workHistoryMap.length > 0 && (
                <div className="flex flex-col gap-4 mt-4">{workHistoryMap}</div>
              )}
              {workHistoryMap.length === 0 && (
                <p className="mt-8 text-center text-slate-600 italic text-sm">
                  Nothing to show
                </p>
              )}
            </div>
          )}
          {profile.accountType === "HACKER" && (
            <div>
              <Divider className="mt-8 bg-slate-300 dark:bg-slate-600 dark:text-slate-300" />
              <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-400">
                <Box2Fill />
                <h5>Projects</h5>
              </div>
              <p className="mx-auto text-sm text-slate-600 dark:text-slate-400 text-center italic">
                {profile.accountType === "HACKER"
                  ? "I was/am a part of the following projects"
                  : "We are responsible for the following projects"}
              </p>
              {projectMap.length > 0 && (
                <div className="mt-6">{projectMap}</div>
              )}{" "}
              {projectMap.length === 0 && (
                <p className="mt-8 text-center text-slate-600 italic text-sm">
                  No projects yet
                </p>
              )}
            </div>
          )}
          {profile.accountType === "COMPANY" && (
            <div>
              <Divider className="mt-8 bg-slate-300 dark:bg-slate-600 dark:text-slate-300" />
              <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-400">
                <BriefcaseFill />
                <h5>Offers</h5>
              </div>
              <p className="text-slate-600 text-center my-6">WIP</p>
            </div>
          )}
          <Divider className="mt-8 bg-slate-300 dark:bg-slate-600 dark:text-slate-300" />
          <div className="flex items-center gap-3 text-xl mt-8 text-center text-slate-600 dark:text-slate-400">
            <Link45deg />
            <h5>Connect</h5>
          </div>
          {linkMap.length > 0 && (
            <div className="flex gap-3 justify-center pt-4">{linkMap}</div>
          )}
          {linkMap.length === 0 && (
            <p className="mt-8 text-center text-slate-600 italic text-sm">
              Nothing to show
            </p>
          )}
        </div>
      )}
    </div>
  );
}
